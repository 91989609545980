<template>
    <defaultSec :title="'案例审核'">
        <keep-alive>
            <el-form :inline="true" :model="formParam" class="demo-form-inline select-wrap" size="mini">
                <el-form-item label="案例名称">
                    <el-input v-model="formParam.experienceName" placeholder="请输入案例名称" clearable></el-input>
                </el-form-item>
                <el-form-item label="案例类型">
                    <el-select v-model="formParam.type"  filterable clearable
                               placeholder="案例类型">
                        <el-option v-for="item in experienceTypeList"
                                   :key="item.value"
                                   :label="item.name"
                                   :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="创建日期">
                    <el-date-picker
                            v-model="pickDate"
                            type="daterange"
                            align="right"
                            unlink-panels
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            format="yyyy 年 MM 月 dd 日"
                            value-format="yyyy-MM-dd"
                            :picker-options="pickerOptions">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="initList">查询</el-button>
                    <el-button type="success" @click="exportCase">导出</el-button>
                </el-form-item>
            </el-form>
        </keep-alive>
        <tablePagination :total="total" :pageSize="formParam.pageSize" @handlePage="handlePage">
            <el-table :data="dataList" border size="mini">
                <el-table-column type="index" label="序号" width="50"></el-table-column>
                <el-table-column label="标识" prop="experienceId" width="80"></el-table-column>
                <el-table-column label="企微" prop="qwNickname" width="120"></el-table-column>
                <el-table-column label="案例名称" prop="experienceName" width="150"></el-table-column>
                <el-table-column label="案例说明" prop="experienceDesc" width="230"></el-table-column>
                <el-table-column label="案例类型" prop="typeName" width="150"></el-table-column>
                <el-table-column label="审核状态"  prop="auditingStatusName" width="80">
                    <template slot-scope="scope">
                        {{scope.row.type == 2||scope.row.type == 3 ? scope.row.auditingStatusName : '-'}}
                    </template>
                </el-table-column>
                <el-table-column label="审核人"  prop="auditingStatusName" width="120">
                    <template slot-scope="scope">
                        {{(scope.row.auditingStatus==0?'未审核':(scope.row.type == 2||scope.row.type == 3)? scope.row.auditingName : '-')}}
                    </template>
                </el-table-column>
                <el-table-column label="备注" prop="eventRemark" width="230"></el-table-column>
                <el-table-column label="创建时间" prop="createTime" width="200">
                </el-table-column>
                <el-table-column label="操作" fixed="right" width="140">
                    <template slot-scope="scope">
                        <el-button-group>
                            <el-button type="warning "  size="mini"
                                       @click="toDetail(scope.row.experienceId,1)">详情
                            </el-button>
                        </el-button-group>
                    </template>
                </el-table-column>
            </el-table>
        </tablePagination>
    </defaultSec>
</template>

<script>
    import defaultSec from '@c/defaultSection'
    import tablePagination from '@c/tablePagination'
    import {baseUrl} from '@/api/http'
    export default {
        data() {
            return {
                formParam: {
                    pageSize: 10,
                    currentPage: 1,
                    type: 0,
                    experienceName:'',
                    qyWeixinUserid: '',
                    materailId:'',
                    start:'',
                    end:'',
                    status:1,
                    auditing:1,
                },
                dataList: [],
                typeList:[],
                qiweiUserList:[],
                experienceTypeList:[],
                showDialog: false,
                total: 0,
                pickDate:[],
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
                value1: '',
                value2: ''
            }

        },
        components: {
            defaultSec, tablePagination
        },
        mounted(){
            this.initList()
            this.getCaseTypeList()
            this.initQwUserList()
        },
        methods: {
            getCaseTypeList(){
                this.experienceTypeList=[]
                this.$apiGet('lechun-cms/experience/getCaseTypeList', {}).then(res => {
                    console.log(res)
                    this.experienceTypeList = res
                    this.experienceTypeList.push({value:0,name:'全部'})
                })
            },
            exportCase(){
                if(this.pickDate!=''&&this.pickDate!=null){
                    this.formParam.start=this.pickDate[0];
                    this.formParam.end=this.pickDate[1];
                }else {
                    this.formParam.start='';
                    this.formParam.end='';
                }
                console.log(`${baseUrl}/lechun-cms/experience/exportCase?auditing=1&pageSize=1000&currentPage=1&start=${this.formParam.start}&end=${this.formParam.end}&pageRouterUrl=1`)
                window.open(`${baseUrl}/lechun-cms/experience/exportCase?auditing=1&pageSize=1000&currentPage=1&start=${this.formParam.start}&end=${this.formParam.end}&pageRouterUrl=1`)
            },
            initQwUserList(){
                this.$apiGet('lechun-cms/scrmCalendar/getQwUser', {}).then(res => {
                    console.log(res)
                    this.qiweiUserList = res
                })
            },
            initList() {
                this.dataList = []
                if(this.pickDate!=''&&this.pickDate!=null){
                    this.formParam.start=this.pickDate[0];
                    this.formParam.end=this.pickDate[1];
                }else {
                    this.formParam.start='';
                    this.formParam.end='';
                }
                this.formParam.auditing=-1
                this.$apiGet('lechun-cms/experience/getAuditingExperienceList', this.formParam).then(res => {
                    console.log(res)
                    this.total = res.total;
                    this.dataList = res.list
                })
            },
            handlePage(page){
                this.formParam.currentPage = page;
                this.initList()
            },
            toDetail(experienceId,operateType){
                console.log('experienceId='+experienceId+",operateType="+operateType)
                this.$router.push({path: '/experienceDetail/:id/:operateType', name: 'experienceDetail', params: {experienceId: experienceId,operateType:operateType}})
            }
        }
    }
</script>

<style scoped>
    .expand-title {
        width: 70px;
        color: #444548;
        line-height: 30px;
        text-align: right;
        margin-right: 10px;
        font-weight: 600;
    }

    .expand-flex {
        justify-content: flex-start;
        align-items: center;
        align-content: center;
    }
</style>